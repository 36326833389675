<template>
  <div>
    <div>
      <van-cell-group inset>
        <!-- 现在密码 -->
        <van-field
          v-model="Nowpassword"
          type="password"
          label="旧密码"
          placeholder="请输入旧密码"
          autocomplete="off"
        />
        <!-- 更改密码 -->
        <van-field
          v-model="Changepassword"
          type="password"
          label="新密码"
          placeholder="请输入新密码"
          autocomplete="off"
        />
        <!-- 确认更改密码 -->
        <van-field
          v-model="affirmChangepassword"
          type="password"
          label="确认新密码"
          placeholder="请再次输入密码"
          autocomplete="off"
        />
      </van-cell-group>
      <!-- 确认提交按钮 -->
      <div class="stylebutton">
        <div style="width: 90%; margin: 0 auto; margin-top: 0.3rem">
          <van-button
            @click="passwordsave"
            style="width: 100%; margin: 0 auto"
            type="primary"
          >
            确认修改
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, getCurrentInstance } from "vue";
  import { Toast } from "vant";
  export default {
    setup() {
      const { proxy } = getCurrentInstance();
      const Nowpassword = ref("");
      const Changepassword = ref("");
      const affirmChangepassword = ref("");
      const savepassword = async (data) => {
        let datapassword = await proxy.$Api.post(
          proxy.$MeApi.updatePassword,
          data
        );
        if (datapassword.data.code == 200) {
          (Nowpassword.value = ""),
            (Changepassword.value = ""),
            (affirmChangepassword.value = "");
          Toast(datapassword.data.msg);
        }
      };
      const passwordsave = function save() {
        if (Nowpassword.value == "") {
          Toast("请输入旧密码");
        } else if (Changepassword.value == "") {
          Toast("请输入新密码");
        } else if (affirmChangepassword.value == "") {
          Toast("请输入确认密码");
        } else if (Changepassword.value != affirmChangepassword.value) {
          Toast("两次输入密码不相同");
        } else if (Nowpassword.value == Changepassword.value) {
          Toast("新密码不能与旧密码相同");
        } else {
          let data = {
            oldPassword: Nowpassword.value,
            newPassword: Changepassword.value,
            checkPassword: affirmChangepassword.value,
          };
          savepassword(data);
        }
      };
      return {
        passwordsave,
        Nowpassword,
        Changepassword,
        affirmChangepassword,
      };
    },
  };
</script>
<style lang="scss" scoped>
  .stylebutton {
    width: 100%;
    height: auto;
  }
</style>
